import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ReleaseUpdateService } from './release-update.service';
import { WINDOW } from '../factory/window.factory';

@Injectable({
  providedIn: 'root',
})
export class ReleaseUpdateGuard {
  constructor(
    private readonly releaseUpdateService: ReleaseUpdateService,
    @Inject(WINDOW) private readonly window: Window,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.releaseUpdateService.updateAvailable()) {
      this.window.location.assign(state.url);
      return false;
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.releaseUpdateService.updateAvailable()) {
      this.window.location.assign(state.url);
      return false;
    }

    return true;
  }
}
